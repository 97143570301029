<template>
    <main class="container" :style="`width: calc(${width || '100'}% - ${sideBarWidth}px) !important; left: ${Math.floor(sideBarWidth/2)}px;`">
        <div class="card tcs-card elevate-2 borderless tcs-top container py-3">
                <div class="tcs-card-header">
                    <slot name="header" />
                </div>
                <slot name="one" />
        </div>
        <div class="card tcs-card elevate-2 tcs-top borderless mt-3 container py-3" >
            <slot name="two" />
        </div>
    </main>
</template>
<script>
import { mapState } from 'vuex'
export default {
    props: ['width'],
    computed: mapState(['sideBarWidth'])
}
</script>

<style scoped>
    main {
        position: relative;
        margin-top: 15px;
        transition: all ease .3s;
    }

    @media (min-width: 790px) {
        .card.tcs-card.tcs-top {
            border-top: 10px solid #6dcff6 !important;
        }
    }

    @media (max-width: 790px) {
        main {
            padding: 0;
            margin-top: 70px;
        }

        main .card:first-child {
            border: 0;
            min-height: calc(100vh - 70px);
        }

        main .card:first-child h4 {
            padding-top: 8px;
        }

        main .card:last-child {
            border-top: 10px solid #6dcff6 !important;
        }

        .card h1 {
            font-size: 34px;
        }

        .container {
            max-width: 100%;
        }
    }
</style>
