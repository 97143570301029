import { render, staticRenderFns } from "./TwoCards.vue?vue&type=template&id=6660c208&scoped=true"
import script from "./TwoCards.vue?vue&type=script&lang=js"
export * from "./TwoCards.vue?vue&type=script&lang=js"
import style0 from "./TwoCards.vue?vue&type=style&index=0&id=6660c208&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6660c208",
  null
  
)

export default component.exports