<template>
    <div>
         <div class="d-flex justify-content-between mb-2">
             <div>
                <button class="btn btn-secondary" @click="toggle"><SettingsIcon size="16"/> Alterar Colunas</button>
            </div>
            <select class="custom-select ml-1" style="width: 150px;" :value="results" @input="changeResults" data-cy="resultados-pagina" >
                <option value="10">10 por página</option>
                <option value="20">20 por página</option>
                <option value="50">50 por página</option>
                <option value="100">100 por página</option>
            </select>
        </div>
        <vue-slide-up-down :active="!retracted">
            <div class="mt-3 mb-2 seletor">
                <h5> Habilitar/desabilitar colunas </h5>
                <div class="seletor__colunas">
                    <div v-for="(value,index) in chosenCols" :key="index" class="custom-control custom-checkbox">
                        <input :disabled="index === 0" type="checkbox" class="custom-control-input" :id="`i_${cols[index]}`" v-model="chosenCols[index]">
                        <label class="custom-control-label" :for="`i_${cols[index]}`">{{cols[index]}}</label>
                    </div>
                </div>
            </div>
        </vue-slide-up-down>
        <div>
            <DataTable async nosearch scroll noedit condensed :loading="loading" :colunas="parsedCols" :linhas="parsedLinhas" :errMsg="errMsg" :state="state" @state-change="setStateDataTable">
                <template #Detector="{ value }">
                    <alert-triangle-icon size="16" v-if="value[1]" /> {{value[0] || '-'}}
                </template>
                <template #Concentrador="{ value }">
                    <alert-triangle-icon size="16" v-if="value[1]" /> {{value[0] || '-'}}
                </template>
                <template #Conversor="{ value }">
                    <alert-triangle-icon size="16" v-if="value[1]" /> {{value[0] || '-'}}
                </template>
                <template #Área="{ value }">
                    <AreaHierarquica :nome="value[0]" :id="value[1]" />
                </template>
            </DataTable>

            <!------------------------------------------- Legenda das cores da tabela -------------------------------------------->
            <div class="legenda">
                <div>
                    <div class="rectangle" style="background-color: #ff8f00;"></div><span style="margin-right: 10px;"> Calibração </span>
                </div>
                <div>
                    <div class="rectangle" style="background-color: #7b1fa2;"></div><span style="margin-right: 10px;"> Manutenção </span>
                </div>
                <div>
                    <div class="rectangle" style="background-color: #757575;"></div><span> Reconhecido </span>
                </div>
            </div>
            <!--------------------------------------------------- Fim da legenda -------------------------------------------------->
        </div>
        <Paginator :page="page" :pages="pages" :count="count" :disabled="loading" :first="first" :last="last" @paginate="paginate" />
    </div>
</template>
<script>
import DataTable from '@/components/DataTable'
import axios from 'axios'
import api from '@/api'
import Paginator from '@/components/Paginator'
import AreaHierarquica from '@/components/AreaHierarquica'
import VueSlideUpDown from 'vue-slide-up-down'

function ms2IntervalStr (dur) {
    let duracao = dur;
    let duracaoStr = '';
    if (duracao > 0) {
        let horas = parseInt(duracao / (60 * 60 * 1000));
        duracao -= horas * (60 * 60 * 1000);
        let minutos = parseInt(duracao / (60 * 1000));
        duracao -= minutos * (60 * 1000);
        let segundos = parseInt(duracao / 1000);
        duracaoStr = `${(''+minutos).padStart(2, '0')}:${(''+minutos).padStart(2, '0')}:${(''+segundos).padStart(2, '0')}`
    }
    return duracaoStr;
}
const classesStyles = {
    'Alerta de Calibração': {
        backgroundColor: '#ff8f00',
        color: 'black',
    },
    'Alerta de Manutenção': {
        backgroundColor: '#7b1fa2',
        color: 'white',
    },
    'Reconhecido': {
        backgroundColor: '#757575',
        color: 'white',
    },

}
export default {
    components: {
        DataTable,
        AreaHierarquica,
        Paginator,
        VueSlideUpDown,
    },
    props: {
        filtros: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            lista: [],
            colsName: ['id','dataHoraInicial', 'dataHoraFinal', null, 'nomeArea', 'nomeDetector','nomeConcentrador', 'nomeConversor', 'classeAlarme', 'severidade','descricao', null, null, 'dataHoraReconhecimento', null, null],
            cols: ['TAG','Data/hora inicial', 'Data/hora final', 'Duração', 'Área', 'Detector', 'Concentrador', 'Conversor', 'Classe de alarme', 'Tipo do alarme', 'Descrição', 'Operador', 'Contato', 'Data/hora reconhecimento', 'Usuário reconhecimento', 'Tempo de reconhecimento'],
            chosenCols: Array(16).fill(true),
            page: 0,
            pages: 1,
            results: 20,
            first: 0,
            last: 0,
            count: 0,
            loading: false,
            errMsg: '',
            retracted: true,
            showGraficos: false,

            state: {
                sortBy: 1,
                sortAsc: false,
                query: ''
            },
        }
    },
    computed: {
        parsedCols() {
            return this.cols.map((c, idx) => this.colsName[idx] ? c : { value: c, sortable: false })
            .filter((el, ind) => this.chosenCols[ind]);
        },
        parsedLinhas() {
            return this.lista.map(row => ({
                ...row,
                cols: row.cols.filter((el, ind) => this.chosenCols[ind])
            }));
        },
        parsedColsName() {
            return this.colsName.filter((el, ind) => this.chosenCols[ind]);
        }
    },
    methods: {
        toggle() {
            this.retracted = !this.retracted;
        },
        setStateDataTable (state) {
            this.state.sortBy = state.sortBy;
            this.state.sortAsc = state.sortAsc;
            this.page = 0;
            this.update(1, this.results);
        },
        changeResults (event) {
            this.page = 0;
            this.update(1, event.target.value);
        },
        paginate (p) {
            if (p === this.page) return;
            this.page = p;
            this.update(this.page, this.results);
        },
        update (page = 1, results = 20) {
            this.loading = true;
            this.filtros.classeAlarme = this.filtros.classeAlarme ? this.filtros.classeAlarme : ['Manutenção', 'Calibração'];
            let url = api.v1.alarme.list(page, results, JSON.stringify(this.filtros), this.parsedColsName[this.state.sortBy], this.state.sortAsc);
            return axios.get(url).then(res => {
                if (res.data.rows) { // paginação ativada
                    this.page = res.data.page;
                    this.pages = res.data.pages;
                    this.first = res.data.first;
                    this.last = res.data.last;
                    this.count = res.data.count;
                    this.results = res.data.results;
                }
                this.lista = (res.data.rows || res.data).map(i => {
                    let inicio = new Date(i.dataHoraInicial);
                    let fim = i.dataHoraFinal ? new Date(i.dataHoraFinal) : '';
                    let rec = i.dataHoraReconhecimento ? new Date(i.dataHoraReconhecimento) : '';
                    let duracao = ms2IntervalStr(fim - inicio);
                    let duracaoReconhecimento = ms2IntervalStr(rec - inicio);
                    return {
                        color: i.dataHoraReconhecimento ? classesStyles['Reconhecido']?.color : classesStyles[`${i.severidade} de ${i.classeAlarme}`]?.color,
                        backgroundColor: i.dataHoraReconhecimento ? classesStyles['Reconhecido']?.backgroundColor : classesStyles[`${i.severidade} de ${i.classeAlarme}`]?.backgroundColor,
                        cols: [i.id, inicio.toLocaleString(), fim.toLocaleString(), duracao, [i.nomeArea, i.idArea], [i.nomeDetector, i.idDetector], [i.nomeConcentrador, i.idConcentrador], [i.nomeConversor, i.idConversor], i.classeAlarme, i.severidade, i.descricao, i.manutencao[0]?.usuario.nome, i.manutencao[0]?.usuario.email,rec.toLocaleString(), i.usuarioReconhecimento?.nome, duracaoReconhecimento]
                    };
                });
                this.errMsg = '';
            }).catch(reason => {
                this.lista = [];
                this.errMsg = reason?.response?.data ?? reason.toString();
            }).then(() => {
                this.loading = false;
            });
        },
    },
    watch: {
        filtros() {
            this.update();
        },
        chosenCols() {
            if (this.chosenCols[this.state.sortBy] === false) {
                this.state.sortBy = 0;
                this.update();
            }
        }
    },
    created () {
        this.update();
    }
}
</script>
<style scoped>
    .rectangle {
        height: 10px;
        width: 25px;
        display: inline-block;
    }
    .legenda {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        position: sticky;
        background-color: white;
        margin: 10px;
        bottom: 10px;
        border-radius: 5px;
        padding: 5px 0;
        border-radius: 1px solid #333;
        box-shadow: 0 1px 3px #333;
    }
    .seletor {
        border: 1px solid #333;
        border-radius: 10px;
        padding: 1rem;
    }
    .seletor__colunas {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    }
</style>
